import request from "@/common/js/request";

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
  return request.post("/worker/login", parameter);
}

export function getUserInfo() {
  return request.get("/worker/info");
}

export function logout() {
  return request.post("/worker/logout");
}

export function getTenantSelect() {
  return request.get("/selects/tenant-list");
}