<template>
  <div id="app">
    <router-view />
  </div>
</template>
<style>

body {
  /*background-color: #cccccc1c;*/
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.even {
  background-color: #f1f1f1;
}
</style>
