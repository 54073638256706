import { createStore } from 'vuex'
import { Toast } from 'vant';
import { getToken, setToken as aSetToken, removeToken } from '@/utils/auth'
import { getUserInfo } from '@/api/login';

export default createStore({
  state: {
    token: null,
    userInfo:{
      realname: '',
      name: '',
    },
    perms: [],
  },
  getters: {
    hasLogin(state){
      return !!state.token;
    },
    getToken(state){
      return state.token;
    },
    getPerms(state) {
      return state.perms;
    }
  },
  mutations: {
    //更新state数据
    setStateAttr(state, param){
      if (param instanceof Array) {
        for (let item of param) {
          state[item.key] = item.val;
        }
      } else {
        state[param.key] = param.val;
      }
    },

    //更新token
    setToken(state, data){
      state['token'] = data;
      aSetToken(data)
    },

    //退出登录
    logout(state){
      state.token = null;
      removeToken()
      // sessionStorage.removeItem("token");
      setTimeout(()=>{
        state.userInfo = {};
      })
    }
  },
  actions: {
      //更新用户信息
      getUserInfo({state, commit}){
        getUserInfo().then(res => {
          // console.log(res);
          const userInfo = res.data.data;
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          commit('setStateAttr', {
            key: 'userInfo',
            val: userInfo
          })
          const workgroupList = localStorage.getItem('workgroupList');
          const { id, realname } = userInfo;
          if(!!userInfo && !!!workgroupList){
            localStorage.setItem("workgroupList", JSON.stringify([{userId:id,userName:realname}]));
          }
        });
    },

  }
})

