import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
    },
    component: () => import("@/views/login"),
  },
  {
    path: "/",
    component: () => import("@/views/layout"),
    children: [
      // {
      //   name: 'home',
      //   path: '/home',
      //   component: () => import('@/views/home'),
      //   meta: {
      //     title: '首页',
      //   },
      // },
      {
        name: "workplace",
        path: "/workplace",
        component: () => import("@/views/workplace"),
        meta: {
          title: "工作台",
        },
      },
      {
        name: "my",
        path: "/my",
        component: () => import("@/views/my"),
        meta: {
          title: "我的",
        },
      },
    ],
    redirect: "/workplace",
  },
  {
    name: "StandardBoardList",
    path: "/workplace/StandardBoardList",
    component: () => import("@/views/asset/StandardBoardList"),
    meta: {
      title: "标件库存",
    },
  },
  {
    name: "BoardList",
    path: "/asset/board-list",
    component: () => import("@/views/asset/BoardList"),
    meta: {
      title: "旧板库存",
    },
  },
  {
    name: "NewBoardList",
    path: "/asset/new-board-list",
    component: () => import("@/views/asset/BoardList"),
    meta: {
      title: "新板库存",
    },
  },
  {
    name: "ProjectList",
    path: "/project/project-list",
    component: () => import("@/views/project/ProjectList"),
    meta: {
      title: "项目生产",
    },
  },
  {
    name: "ProjectItems",
    path: "/project/project-item",
    component: () => import("@/views/project/dialogs/ProjectItems"),
    meta: {
      title: "作业详情",
    },
  },
  {
    name: "MakeOrderCenter",
    path: "/project/make-order-center",
    component: () => import("@/views/project/dialogs/MakeOrderCenter"),
    meta: {
      title: "接单中心",
    },
  },
  {
    name: "DetailRecordDialog",
    path: "/project/detail",
    component: () => import("@/views/project/dialogs/DetailRecordDialog"),
    meta: {
      title: "查看详情",
    },
  },
  {
    name: "ProjectRepairList",
    path: "/projectRepair/project-repair-list",
    component: () => import("@/views/projectRepair/ProjectRepairList"),
    meta: {
      title: "补单管理",
    },
  },
  {
    name: "inventoryList",
    path: "/projectRepair/inventoryList",
    component: () => import("@/views/projectRepair/modules/inventoryList"),
    meta: {
      title: "清单管理",
    },
  },
  {
    name: "repairProductionList",
    path: "/projectRepair/productionList",
    component: () => import("@/views/projectRepair/modules/productionList"),
    meta: {
      title: "补单生产单",
    },
  },
  {
    name: "repairProjectDetail",
    path: "/projectRepair/projectDetail",
    component: () => import("@/views/projectRepair/modules/projectDetail"),
    meta: {
      title: "生产单明细",
    },
  },
  {
    name: "warehouseList",
    path: "/projectRepair/warehouseList",
    component: () => import("@/views/projectRepair/modules/warehouseList"),
    meta: {
      title: "提库单管理",
    },
  },
  {
    name: "ProjectProductionList",
    path: "/projectProduction/project-production-list",
    component: () => import("@/views/projectProduction/ProjectProductionList"),
    meta: {
      title: "项目排产单",
    },
  },
  {
    name: "ProjectProductionDetail",
    path: "/projectProduction/productionDetail",
    component: () =>
      import("@/views/projectProduction/modules/productionDetail"),
    meta: {
      title: "排产单明细",
    },
  },
  {
    name: "ShotBlastList",
    path: "/recycle/shot-blast-list",
    component: () => import("@/views/recycle/shotBlastList"),
    meta: {
      title: "抛丸管理",
    },
  },
  {
    name: "KnockCementList",
    path: "/recycle/knock-cement-list",
    component: () => import("@/views/recycle/knockCementList"),
    meta: {
      title: "敲水泥管理",
    },
  },
  {
    name: "AssetOutPackageList",
    path: "/package/asset-out-package",
    component: () => import("@/views/package/AssetOutPackageList"),
    meta: {
      title: "项目提库",
    },
  },
  {
    name: "AssetOutPackageItems",
    path: "/package/asset-out-package-items",
    component: () => import("@/views/package/dialogs/AssetOutPackageItems"),
    meta: {
      title: "清单列表",
    },
  },
  {
    name: "AssetOutPackageDetail",
    path: "/package/asset-out-package-detail",
    component: () => import("@/views/package/dialogs/AssetOutPackageDetail"),
    meta: {
      title: "查看详情",
    },
  },
  {
    name: "InvoicesPackageList",
    path: "/invoices/asset-out-package",
    component: () => import("@/views/invoices/AssetOutPackageList"),
    meta: {
      title: "提库单查询",
    },
  },
  {
    name: "InvoicesPackageItems",
    path: "/invoices/asset-out-package-items",
    component: () => import("@/views/invoices/dialogs/AssetOutPackageItems"),
    meta: {
      title: "提库单列表",
    },
  },
  {
    name: "InvoicesPackageDetail",
    path: "/invoices/asset-out-package-detail",
    component: () => import("@/views/invoices/dialogs/AssetOutPackageDetail"),
    meta: {
      title: "查看详情",
    },
  },
  {
    name: "batchList",
    path: "/project/batch",
    component: () => import("@/views/project/batchList"),
    meta: {
      title: "批次查询",
    },
  },
  {
    name: "BatchDetailRecordDialog",
    path: "/project/batch-detail",
    component: () => import("@/views/project/dialogs/BatchDetailRecordDialog"),
    meta: {
      title: "查看详情",
    },
  },
  {
    name: "index",
    path: "/package/index",
    component: () => import("@/views/exemptPackage/index"),
    meta: {
      title: "拼装打包",
    },
  },
  {
    name: "ExemptPackageList",
    path: "/package/exempt-package",
    component: () => import("@/views/exemptPackage/ExemptPackageList"),
    meta: {
      title: "免拼打包",
    },
  },
  {
    name: "ExemptPackageItems",
    path: "/package/exempt-package-items",
    component: () => import("@/views/exemptPackage/dialogs/ExemptPackageItems"),
    meta: {
      title: "清单列表",
    },
  },
  {
    name: "ExemptPackageDetail",
    path: "/package/exempt-package-detail",
    component: () =>
      import("@/views/exemptPackage/dialogs/ExemptPackageDetail"),
    meta: {
      title: "查看详情",
    },
  },
  {
    name: "StarvedFeedingDeatail",
    path: "/package/starved-feeding-detail",
    component: () =>
      import("@/views/exemptPackage/dialogs/StarvedFeedingDeatail"),
    meta: {
      title: "缺料清单",
    },
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

export { router };
