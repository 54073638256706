import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import Vant from "vant";
import store from "./store";
import axios from "./common/js/request";
import { Toast } from "vant";
import * as echarts from "echarts";
import "vant/lib/index.css";
import "./assets/font/iconfont.css";
import "./assets/font_1/iconfont.css";
import "./assets/font_1/iconfont.js";
import "./assets/font_2/iconfont.css";
import "./assets/font_2/iconfont.js";
import "./permission"; // permission control
import "./assets/css/reset.css";
import Overlay from "@/components/Overlay";

const app = createApp(App);

app.component('m-overlay', Overlay);
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$echarts = echarts;
app.config.globalProperties.$toast = Toast;
app.use(store).use(router).use(Vant).mount("#app");
