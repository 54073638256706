<template>
  <div
    id="m-overlay"
    v-show="visiable"
    :style="{
      backgroundColor:'rgba(0, 0, 0, 0.3)',
    }"
  >
    <!-- TODO -->
    <slot></slot>

    <van-loading type="spinner" color="#1989fa" v-show="visiable" >
      加载中...
    </van-loading>

  </div>
</template>

<script>
export default {
  name: "Overlay",
  components: {},
  props: {
    visiable: Boolean,
  },
  data() {
    return {};
  },
  methods: {
  },
};
</script>
<style>
#m-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>