import axios from "axios";
import qs from "qs";
import { Toast } from 'vant';
import store from '@/store' //vuex
import { router } from '@/router';
import { getToken } from '@/utils/auth'

axios.defaults.baseURL = '/ht/' //测试

//post请求头
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";

//允许跨域携带cookie信息
axios.defaults.withCredentials = true;
//设置超时
axios.defaults.timeout = 15000;

axios.interceptors.request.use(
    config => {
        // console.log("sessionStorage",sessionStorage)
        let token = getToken();

        // const tenantId = document.location.pathname.split("/")[1];
        // config.headers.tenantId = tenantId

        if (config.data && config.data.tenantId) {
            config.headers[ 'tenantId' ] = config.data.tenantId
        }

        if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers["Authorization"] = token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        if (response && response.status == 200) {
            const data = response.data;
            if (data.code == 401){
                //登录过期,权限不足
                let message = data.message
                if (!message) {
                    message = "登陆过期";
                }
                Toast(message);
                //清除token
                store.commit('logout');
                router.replace({
                    path:"/login"
                })
            } else  if (data.code != 200){
                let message = "业务异常，请联系管理员";
                if (data.message) {
                    message = data.message;
                }
                Toast(message);
                return Promise.reject({ errno: 500, errmsg: message});
            } else {
                return Promise.resolve(response);
            }
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        // console.log(error.response.status == 401);
        if (error.response.status == 401) {
            //登录过期,权限不足
            Toast("登陆过期");
            //清除token
            store.commit('logout');
            router.replace({
                path:"/login"
            })
        } else {
            // console.warn(error);
            Toast('连接超时,请稍后再试!');
            return Promise.reject({ errno: 500, errmsg: "连接超时,请联系客服" });
        }
    }
);
export default {
    /**
     * @param {String} url
     * @param {Object} data
     * @returns Promise
     */
    post(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'post',
                    url,
                    // data: qs.stringify(data),
                    data: data,
                })
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                });
        })
    },

    get(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'get',
                    url,
                    params: data,
                })
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
};